/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { forwardRef } from 'react';
import { Text, View, ArrowRight, TextProps, Button, Icon } from '@az/starc-ui';
import type { ContentStackBlogtypeGrid, ContentStackFeaturedContent } from '../../interface';
import styles from './CMSImageContentCard.module.scss';
import cx from 'classnames';
import { CMSImage } from '../CMSImage/CMSImage';
import { isModalLink } from '../../utils/isModalLink';
import { ModalLink } from '@/components/ModalLink/ModalLink';

type Props =
  | NonNullable<ContentStackBlogtypeGrid['blog_content']>[0]
  | (NonNullable<ContentStackFeaturedContent['feature_content']>[0] & {
      withBackground?: boolean;
      textColor?: TextProps['color'];
    });

export const CMSImageContentCard = forwardRef<HTMLImageElement, Props>(function CMSImageContentCard(
  { image, heading, description, ...optionalProps },
  ref
) {
  const ctaLink = 'link_cta' in optionalProps ? optionalProps.link_cta : undefined;
  const withBackground =
    'withBackground' in optionalProps ? optionalProps.withBackground : undefined;
  const linkTarget = 'target' in optionalProps ? optionalProps.target ?? '_self' : '_self';

  const textColor = 'textColor' in optionalProps ? optionalProps.textColor : undefined;

  const hasModalLink = isModalLink(ctaLink?.href ?? '');

  return (
    <View className={styles.container}>
      {image && (
        <View className={styles.imgContainer}>
          <CMSImage image={image} ref={ref} />
          {withBackground && <View className={cx(styles.imgBackground, styles.withBackground)} />}
        </View>
      )}
      <View className={cx(styles.contentContainer, withBackground && styles.withBackground)}>
        {heading && (
          <Text className={styles.heading} size="100" weight="bold" color={textColor}>
            {heading}
          </Text>
        )}
        {description && (
          <Text className={styles.description} size="087" color={textColor}>
            {description}
          </Text>
        )}
        {hasModalLink && ctaLink?.title && (
          <ModalLink
            modalId={ctaLink.href}
            className={cx(styles.linkButton, styles.modalLinkButton)}
          >
            <Text fontFamily="headline" weight="medium" size="087" textCase="uppercase">
              {ctaLink.title}
            </Text>
            <Icon size={5} svg={ArrowRight} />
          </ModalLink>
        )}
        {!hasModalLink && ctaLink?.href && ctaLink.title && (
          <Button
            href={ctaLink.href}
            attributes={{
              target: linkTarget,
            }}
            variant="ghost"
            endIcon={ArrowRight}
            className={styles.linkButton}
          >
            <Text size="087">{ctaLink.title}</Text>
          </Button>
        )}
      </View>
    </View>
  );
});
