/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { useLabels } from '@/hooks/useLabels';
import styles from './Balance.module.scss';
import { Text, View } from '@az/starc-ui';

type Props = {
  activity: number;
  isReturn?: boolean;
  credit?: boolean;
};

const labelMap = {
  lblrewardsGained: 'label_Rewards_modal_RewardsGained',
  lblrewardsUsed: 'label_Rewards_modal_RewardsUsed',
  lblrewardsAdjustment: 'label_MyAccountMyProfile_orderhistory_RewardsAdjustment',
  lblrewardsCredit: 'label_MyAccountMyProfile_orderhistory_RewardsCredit',
  lblcreditAdjustment: 'label_MyAccountMyProfile_orderhistory_CreditAdjustment',
};

const getBalanceMessage = (
  balanceChange: number,
  credit: boolean,
  isReturn: boolean,
  labels: Record<string, string>
) => {
  let message = '';
  const isPositiveBalance = balanceChange > 0;

  if (credit) {
    if (isPositiveBalance) {
      message = `${labels.lblrewardsCredit} +${balanceChange}`;
      return message;
    }

    if (isReturn) {
      message = labels.lblcreditAdjustment;
    }
    return message;
  }

  const balanceContent = isPositiveBalance ? labels.lblrewardsGained : labels.lblrewardsUsed;
  const balanceRounded = Math.abs(balanceChange).toFixed(2);

  message = `${balanceContent} ${isPositiveBalance ? '+' : '-'}$${balanceRounded}`;

  return message;
};

const Balance = ({ activity, isReturn = false, credit = false }: Props) => {
  const labels = useLabels(labelMap);
  const isPositiveBalance = activity > 0;
  return (
    <View data-testid="rewards_balance_rewards_activity">
      <Text
        fontStyle="italic"
        className={cx(
          styles.balanceMessage,
          !credit && {
            [styles.redeemedReward]: !isPositiveBalance,
            [styles.gainedReward]: isPositiveBalance,
          }
        )}
      >
        {getBalanceMessage(activity, credit, isReturn, labels)}
      </Text>
    </View>
  );
};

export { Balance };
